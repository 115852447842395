import { gql } from './codegen';

// Login
export const LOGIN = gql(`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      bearerToken
      email
      accountId
    }
  }
`);

export const UPDATE_FILE_NODE_NAME = gql(`
  mutation UpdateFileNodeName($nodeId: ID!, $name: String!) {
    updateFileNodeName(nodeId: $nodeId, name: $name)
  }
`);

export const UPDATE_FOLDER_NODE_NAME = gql(`
  mutation UpdateFolderNodeName($nodeId: ID!, $name: String!) {
    updateFolderNodeName(nodeId: $nodeId, name: $name)
  }
`);

export const UPDATE_SYSTEM_NODE_NAME = gql(`
  mutation UpdateSystemNodeName($systemNodeId: ID!, $name: String!) {
    updateSystemNodeName(systemNodeId: $systemNodeId, name: $name)
  }
`);

export const UPDATE_SYSTEM_NODES_POSITION = gql(`
  mutation UpdateSystemNodesPosition($systemNodeIds: [ID]!, $parentSystemNodeId: ID!) {
    updateSystemNodesPosition(systemNodeIds: $systemNodeIds, parentSystemNodeId: $parentSystemNodeId)
  }
`);

export const UPSERT_PERMISSION_POLICIES = gql(`
  mutation UpsertPermissionPolicies($permissionPolicies: [PermissionPolicyInput]) {
    upsertPermissionPolicies(permissionPolicies: $permissionPolicies)
  }
`);

export const DELETE_PERMISSION_POLICIES = gql(`
  mutation DeletePermissionPolicies($permissionPolicies: [PermissionPolicyInput]) {
    deletePermissionPolicies(permissionPolicies: $permissionPolicies)
  }
`);

export const DOWNLOAD_SYSTEM_NODES = gql(`
  mutation DownloadSystemNodes($nodeIds: [ID]!, $name: String, $note: String) {
    downloadSystemNodes(nodeIds: $nodeIds, name: $name, note: $note)
  }
`);

export const CREATE_SYSTEM_FOLDER_NODE = gql(`
  mutation CreateSystemFolderNode($input: CreateSystemFolderNodeInput!) {
    createSystemFolderNode(input: $input) {
      id
      kind
      parentNodeId
      name
      createdAt
      createdUser {
        id
        displayName
        firstName
        lastName
      }
      lastDownloadedAt
      lastDownloadedUser {
        id
        displayName
        firstName
        lastName
      }
    }
  }
`);

export const CREATE_PROJECT_FILE_NODES_FROM_FILES = gql(`
  mutation CreateProjectFileNodesWithNodeTree($projectId: ID!, $parentNodeId: ID, $nodeIds: [ID!]!) {
    createProjectFileNodesWithNodeTree(nodeIds: $nodeIds, siteId: $projectId, parentNodeId: $parentNodeId) {
      id
      kind
      name
    }
  }
`);

export const CREATE_PROJECT_FOLDER_NODE = gql(`
  mutation CreateProjectFolderNode($projectId: ID!, $parentNodeId: ID, $name: String!) {
    createProjectFolderNode(parentNodeId: $parentNodeId, siteId: $projectId, name: $name) {
      id
      kind
      name
    }
  }
`);

export const DELETE_SYSTEM_NODE = gql(`
  mutation DeleteSystemNode($systemNodeId: ID!) {
    deleteSystemNode(systemNodeId: $systemNodeId)
  }`);

export const CREATE_NODE_SHARE_LINK = gql(`
  mutation CreateNodeShareLink($nodeId: ID!, $name: String!, $actionType: String!) {
    createNodeShareLink(nodeId: $nodeId, name: $name, actionType: $actionType) {
      id
      shareToken
    }
  }
`);

export const ABORT_MULTIPART_UPLOAD = gql(`
  mutation AbortMultiPartUpload(
    $bucket: String!
    $key: String!
    $uploadId: String!
  ) {
    abortMultipartUpload(
      bucket: $bucket
      key: $key
      uploadId: $uploadId
    )
  }
`);

export const CREATE_MULTIPART_UPLOAD_FOR_FILE_IMPORT_REQUEST = gql(`
  mutation CreateMultipartUploadForFileImportRequest($dataImportRequestId: ID!, $fileImportRequestId: ID!) {
    createMultipartUploadForFileImportRequest(dataImportRequestId: $dataImportRequestId, fileImportRequestId: $fileImportRequestId) {
      bucket
      key
      uploadId
    }
  }
`);

export const CREATE_PRESIGNED_UPLOAD_PART_URL = gql(`
  mutation CreatePresignedUploadPartUrl(
    $bucket: String!
    $key: String!
    $partNumber: Int!
    $uploadId: String!
  ) {
    createPresignedUploadPartUrl(
      bucket: $bucket
      key: $key
      partNumber: $partNumber
      uploadId: $uploadId
    )
  }
`);

export const COMPLETE_MULTIPART_UPLOAD = gql(`
  mutation CompleteMultipartUpload(
    $bucket: String!
    $key: String!
    $parts: [MultiPartInput!]!
    $uploadId: String!
  ) {
    completeMultipartUpload(
      bucket: $bucket
      key: $key
      parts: $parts
      uploadId: $uploadId
    )
  }
`);

export const CREATE_DATA_IMPORT_REQUEST = gql(`
  mutation CreateDataImportRequest(
    $featureType: String!
    $name: String!
    $persistType: String!
    $priority: String!
    $rootFolderIdempotencyKeyAndName: FolderIdempotencyKeyAndNameInput!
    $systemFolderNodeId: ID
  ) {
    createDataImportRequest(
      featureType: $featureType
      name: $name
      persistType: $persistType
      priority: $priority
      rootFolderIdempotencyKeyAndName: $rootFolderIdempotencyKeyAndName
      systemFolderNodeId: $systemFolderNodeId
    )
  }
`);

export const UPDATE_STATUS_FOR_DATA_IMPORT_REQUEST = gql(`
  mutation UpdateStatusForDataImportRequest($status: String!, $dataImportRequestId: ID!) {
    updateStatusForDataImportRequest(status: $status, dataImportRequestId: $dataImportRequestId)
  }
`);

export const CREATE_FOLDER_IMPORT_REQUEST_FOR_DATA_IMPORT_REQUEST = gql(`
  mutation CreateFolderImportRequestForDataImportRequest(
    $dataImportRequestId: ID!
    $parentFolderNodeIdempotencyKey: String!
    $childFolderNodeIdempotencyKeyAndNames: [FolderIdempotencyKeyAndNameInput!]!
  ) {
    createFolderImportRequestForDataImportRequest(
      dataImportRequestId: $dataImportRequestId
      parentFolderNodeIdempotencyKey: $parentFolderNodeIdempotencyKey
      childFolderNodeIdempotencyKeyAndNames: $childFolderNodeIdempotencyKeyAndNames
    )
  }
`);

export const UPDATE_STATUS_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation UpdateStatusForFolderImportRequest($input: UpdateStatusForFolderImportRequestInput!) {
    updateStatusForFolderImportRequest(input: $input)
  }
`);

export const CREATE_FILE_NODE_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation CreateFileNodeForFolderImportRequest(
    $dataImportRequestId: ID!,
    $fileIdempotencyKeyStorageAndMetadata: [FileIdempotencyKeyStorageAndMetadataInput!]!
    $folderImportRequestId: ID!,
  ) {
    createFileNodeForFolderImportRequest(
      dataImportRequestId: $dataImportRequestId
      fileIdempotencyKeyStorageAndMetadata: $fileIdempotencyKeyStorageAndMetadata
      folderImportRequestId: $folderImportRequestId
    )
  }
`);

export const CREATE_FOLDER_NODE_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation CreateFolderNodeForFolderImportRequest(
    $childFolderImportRequestIds: [ID]!
    $dataImportRequestId: ID!
    $parentFolderImportRequestId: ID
  ) {
    createFolderNodeForFolderImportRequest(
      childFolderImportRequestIds: $childFolderImportRequestIds
      dataImportRequestId: $dataImportRequestId
      parentFolderImportRequestId: $parentFolderImportRequestId
    )
  }
`);

export const TRACK_FILES_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation TrackFilesForFolderImportRequest(
    $dataImportRequestId: ID!
    $folderImportRequestId: ID!
    $files: [FileItemInput!]!
  ) {
    trackFilesForFolderImportRequest(
      dataImportRequestId: $dataImportRequestId
      folderImportRequestId: $folderImportRequestId
      files: $files
    )
  }
`);

export const CREATE_IMAGE_THUMBNAILS_FOR_PERSISTED_IMAGES_BY_IDS = gql(`
  mutation CreateImageThumbnailsForPersistedImagesByIds($fileIds: [ID]!) {
    createImageThumbnailsForPersistedImagesByIds(fileIds: $fileIds)
  }
`);

export const DELETE_SYSTEM_NODES_BY_PARENT_AND_NODE_IDS = gql(`
  mutation DeleteSystemNodesByParentAndNodeIds($parentNodeId: ID, $systemNodeIds: [ID!]!) {
    deleteSystemNodesByParentAndNodeIds(parentNodeId: $parentNodeId, systemNodeIds: $systemNodeIds) {
      deletedSystemNodeIds
      nonDeletableSystemNodeIdsWithErrorDetails {
        errorDetails {
          childNodeIds
          dependants {
            domainName
            entityId
          }
          linkNodeIds
        }
        systemNodeId
      }
      success
    }
  }  
`);

export const DELETE_PROJECT_SYSTEM_NODES_BY_PARENT_AND_NODE_IDS = gql(`
  mutation DeleteProjectSystemNodesByParentAndNodeIds($parentNodeId: ID, $projectId: ID!, $systemNodeIds: [ID!]!) {
    deleteProjectSystemNodesByParentAndNodeIds(parentNodeId: $parentNodeId, projectId: $projectId, systemNodeIds: $systemNodeIds) {
      deletedSystemNodeIds
      nonDeletableSystemNodeIdsWithErrorDetails {
        errorDetails {
          childNodeIds
          dependants {
            domainName
            entityId
          }
          linkNodeIds
        }
        systemNodeId
      }
      success
    }
  }  
`);
