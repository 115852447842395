// import { UsageMeter } from '@/components/UsageMeter';
// import { DeleteConfirmDialog } from '@/components/DeleteConfirmDialog';
import { useFetchAndManageSystemNodes } from '@/hooks/useFetchAndManageSystemNodes';
import {
  handlePaginationChange,
  setRowSelection,
  setSorting,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { AddUserOrUserGroupDialog } from '../AddUserOrUserGroupDialog';
import { EditAccessDialog } from '../EditAccessDialog';
import { MoveToDialog } from '../MoveToDialog';
import { BatchActionFloatingToolbar } from './BatchActionFloatingToolbar/BatchActionFloatingToolbar';
import { DownloadNoteModal } from './DownloadNoteModal';
import { Downloader } from './Downloader';
import { HeaderActions } from './HeaderActions';
import { Pagination } from './Pagination';
import { SearchFilesAndFolders } from './SearchFilesAndFolders';
import { SystemNodesDisplayTable } from './SystemNodesDisplayTable';
import { FoldersPageTableData, useFoldersColumns } from './useFoldersPageColumns';

export type TableType = ReturnType<typeof useReactTable<FoldersPageTableData>>;

export const DataManagementPage = () => {
  const { totalNumberOfPages, isFetchingSystemNodes, totalNumberOfFiles, displaySystemNodes } =
    useFetchAndManageSystemNodes();

  const {
    pagination: { pageIndex, pageSize },
    sorting,
    rowSelection,
  } = useDataManagementPageStore(state => state);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { columns } = useFoldersColumns();

  const table = useReactTable({
    columns,
    data: displaySystemNodes,
    enableRowSelection: true,
    state: { sorting, rowSelection, pagination },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getRowId: row => row.id,
    onRowSelectionChange: setRowSelection,
    pageCount: totalNumberOfPages,
    onPaginationChange: handlePaginationChange,
    manualPagination: true,
  }) as TableType;

  return (
    <div className={cn('h-full w-full', 'flex flex-row flex-nowrap', 'bg-neutral-100')}>
      <div className={cn('h-full w-full px-6 overflow-hidden flex flex-col flex-nowrap')}>
        <div
          className={cn(
            'b-b-1 b-b-neutral-300 b-b-solid',
            'flex items-center justify-start flex-none',
            'pb-3 pt-30px',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800', 'flex-1')}>Data Management</h1>
          <HeaderActions />
        </div>

        <div className="flex flex-none justify-between pt-2">
          <div className="h-fit flex items-center justify-center gap-6">
            <h3 className="color-neutral-800 typo-text-s">Manage data, files, and folders.</h3>
            {/* We are commenting out the usage meter for now as the storage calculation is not reliable */}
            {/* <UsageMeter /> */}
          </div>
          <SearchFilesAndFolders />
        </div>

        <SystemNodesDisplayTable
          isFetchingSystemNodes={isFetchingSystemNodes}
          table={table}
          totalNumberOfFiles={totalNumberOfFiles}
        />

        <div className="flex flex-none justify-center p-y-3">
          <BatchActionFloatingToolbar
            selectedRows={table.getFilteredSelectedRowModel().rows.map(row => row.original)}
          />

          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            isFetching={isFetchingSystemNodes}
            isNextPageDisabled={!table.getCanNextPage()}
            isPreviewPageDisabled={!table.getCanPreviousPage()}
            totalPages={table.getPageCount()}
            onPageChange={newPage => {
              table.setPageIndex(newPage - 1);
            }}
          />
        </div>

        <DownloadNoteModal />

        <Downloader />

        <EditAccessDialog />

        <AddUserOrUserGroupDialog />

        <MoveToDialog />

        {/* <DeleteConfirmDialog /> */}
      </div>
    </div>
  );
};
