import { DownloadSystemNodesMutationVariables } from '@/graphql/codegen/graphql';
import { DOWNLOAD_SYSTEM_NODES } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { setIsOpenDownloadDialog, setIsRequestingDownload } from '@/stores/download';
import { downloadStore } from '@/stores/downloadStore/downloadStore';
import { getShareLinkToken } from '@/utils/shareLink';
import { DATASETS_ADVANCED_DOWNLOADER, DATASETS_ZIP_DOWNLOADER } from '@/utils/split';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuItem } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';

export const DownloadFolderMenuItem = (props: { nodeId: string }) => {
  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER, DATASETS_ZIP_DOWNLOADER]);
  const isZipDownloaderEnabled = treatments[DATASETS_ZIP_DOWNLOADER].treatment === 'on';
  const hasShareLink = !!getShareLinkToken();

  const downloadSystemNodes = useMutation({
    mutationFn: (variables: DownloadSystemNodesMutationVariables) => {
      return request(DOWNLOAD_SYSTEM_NODES, variables);
    },
  });

  const downloadSystemFolderNode = () => {
    downloadSystemNodes.mutate({ nodeIds: [props.nodeId] });
    setIsRequestingDownload(true);
    setIsOpenDownloadDialog(true);
  };

  const downloadV2 = () => {
    downloadStore.createTaskManagerAndStart([props.nodeId]);
  };

  const isEnabled = isZipDownloaderEnabled || !hasShareLink;
  const download = isZipDownloaderEnabled ? downloadV2 : downloadSystemFolderNode;

  return (
    <DropdownMenu.Item asChild className="cursor-pointer outline-none">
      <MenuItem disabled={!isEnabled} onClick={download}>
        Download
      </MenuItem>
    </DropdownMenu.Item>
  );
};
