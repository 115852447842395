import { NODE_KIND, NodeKind } from '@/constants/node';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import { useDataManagementPageStore } from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import { Button } from '@skand/ui';
import { Row } from '@tanstack/react-table';
import { DetailsPopupButton } from './DetailsDropdownButton';
import { RowActions } from './RowActions';
import { FoldersPageTableData } from './useFoldersPageColumns';

export interface RowActionsContainerProps {
  row: Row<FoldersPageTableData>;
  onUpdateDetails: (
    kind: NodeKind,
    extension: string,
    updates: { systemNodeId: string; name: string },
  ) => void;
  onEditAccess: (systemNodeId: string) => void;
}

export const RowActionsContainer = ({
  row,
  onUpdateDetails,
  onEditAccess,
}: RowActionsContainerProps) => {
  const editingSystemNodeId = useDataManagementPageStore(state => state.editingSystemNodeId);
  const isOpenEditAccessDialog = useDataManagementPageStore(state => state.isOpenEditAccessDialog);

  const { userHasAdminPermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds: [row.original.id],
  });

  return (
    <div className="w-full flex items-center justify-end gap-3">
      <DetailsPopupButton nodeDetails={row.original} onUpdateDetails={onUpdateDetails} />
      <Button
        primary
        active={isOpenEditAccessDialog && row.original.id === editingSystemNodeId}
        className={cn('whitespace-nowrap', {
          'hover:cursor-pointer': userHasAdminPermission,
        })}
        disabled={row.original.kind === NODE_KIND.FILE_NODE || !userHasAdminPermission}
        size="s"
        onClick={() => onEditAccess(row.original.id)}
      >
        Edit access
      </Button>
      <RowActions row={row} />
    </div>
  );
};
