import { File } from '@/graphql/codegen/graphql';
import { useSystemNodesBySystemNodeIdsForPreview } from '@/hooks/useSystemNodesBySystemNodeIdsForPreview';
import { downloadFiles, setIsOpenDownloadDialog } from '@/stores/download';
import { downloadStore } from '@/stores/downloadStore/downloadStore';
import { DATASETS_ADVANCED_DOWNLOADER, DATASETS_ZIP_DOWNLOADER } from '@/utils/split';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuItem } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';

export const DownloadFileMenuItem = (props: { nodeId: string }) => {
  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER, DATASETS_ZIP_DOWNLOADER]);
  const isAdvancedDownloaderEnabled = treatments[DATASETS_ADVANCED_DOWNLOADER].treatment === 'on';
  const isZipDownloaderEnabled = treatments[DATASETS_ZIP_DOWNLOADER].treatment === 'on';

  const { findSystemNodeById } = useSystemNodesBySystemNodeIdsForPreview();
  const systemNode = findSystemNodeById(props.nodeId);

  const downloadSystemFileNode = async () => {
    if (systemNode?.file?.signedGetObjectDownloadUrl) {
      downloadFiles([systemNode.file] as File[], isAdvancedDownloaderEnabled);
      setIsOpenDownloadDialog(true);
    }
  };

  const downloadV2 = () => {
    downloadStore.createTaskManagerAndStart([props.nodeId]);
  };

  const download = isZipDownloaderEnabled ? downloadV2 : downloadSystemFileNode;

  return (
    <DropdownMenu.Item asChild className="cursor-pointer outline-none">
      <MenuItem onClick={download}>Download</MenuItem>
    </DropdownMenu.Item>
  );
};
